.row{
    display:flex;
    flex-direction:column;
    gap:16px;
    width:100%;
    height:100%;
    padding: 0 16px;
    
  }
  
  .col{
    width:100%;

  }
  
  .col img, .col video{
    position:relative;
    width:100%;
    object-fit:cover;
  }


  .projectPageContainer{
    position: absolute;
    width: 100%;
    display:flex;
    flex-direction:column;
    gap:16px;
}

.next{
  padding: 24px;
  margin-top: 80px;
}

.txt{
  font-family: 'neue_montreal_light';
  color: $white;
  font-size: 7vw;
  padding: 16px;
}

.projectHero{
  top: 0;
  left: 0;
  height: 100vh;
  z-index: -10;
  width: 100%;
  font-family: 'neue_montreal_light';
  text-transform: uppercase;
  color: $white;
  font-size: 12vw;
  padding: 0 24px;

  h2{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
  }
  }
  
  @media only screen and (min-width: 768px) {

    .txt{
      font-family: 'neue_montreal_light';
      color: $white;
      font-size: 2.5vw;
      padding: 40px;
    }

    .col{
      padding: 40px;
    }

    .projectHero{
      top: 0;
      left: 0;
      height: 100vh;
      z-index: -10;
      width: 100%;
      font-family: 'neue_montreal_light';
      text-transform: uppercase;
      color: $white;
      font-size: 12vw;
      padding: 0 40px;
  
      h2{
          position: relative;
          top: 50%;
          transform: translateY(-50%);
      }
  }

    .projectPageContainer{
        position: absolute;
        width: 100%;
        display:flex;
        flex-direction:column;
        gap:16px;
    }
    
    .row{
    display:flex;
    flex-direction:row;
    gap:16px;
    width:100%;
    height:100%;
    padding: 0;
  }
    
    .col img, .col video{
    position:relative;
    width:80%;
    object-fit:cover;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }

  .next{
    padding: 40px;
    margin-top: 80px;
  }

  .last{
    margin-top:-450px;
  }
  
    
  div [data-random="1"]{
    transform: translate(-45%,-52%);
  }
  
  div [data-random="2"]{
    transform: translate(-52%,-48%);
  }
  
  div [data-random="3"]{
    transform: translate(-42%,-55%);
  }

  div [data-random="4"]{
    transform: translate(-47%,-51%);
  }

  div [data-random="5"]{
    transform: translate(-48%,-51%);
  }
  
  }