@import "scss/config/config";
@import "scss/components/menu";
@import "scss/components/projects";
@import "scss/components/overlay";
@import "scss/components/text-anim";



@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}

@keyframes opacify{
    from{opacity: 1;}
    to{opacity: .7;}
}

@keyframes rotate{
    from{transform:translate(-30%,-30%) rotate(0);}
    to{transform: translate(-30%,-30%)rotate(135deg); }
}

@keyframes rotateReverse{
    from{transform: translate(-30%,-30%) rotate(135deg);}
    to{transform: rotate(0)translate(-30%,-30%);}
}

* {
    cursor:none;
  }
  
  .ml13 .letter {
    display:inline-flex;
    line-height: 1em;
  }

  .cursor{
      width:65px;
      height: 65px;
      backface-visibility: hidden;
      pointer-events: none;
      mix-blend-mode: difference;
      background-color: rgba(255,255,255, 0);
      position: fixed;
      overflow: visible;
      z-index: 900;
      transform: translate(-30%,-30%);
      background-image: url(img/cursor.svg);
      background-repeat: no-repeat;
  }

  .cursor-hover{
    background-image: url(img/cursorHover.svg);
    overflow: visible;
    mix-blend-mode: difference;
    position: fixed;
    transform: translate(-30%,-30%);


  }

  .rotate{
    transform: translate(-30%,-30%) rotate(135deg) ;
    animation: rotate .3s ease ;
    position: fixed;
    transform: translate(-30%,-30%);
}

.rotateReverse{
    transform: translate(-30%,-30%) rotate(135deg) ;
    animation: rotateReverse .3s ease forwards ;
    position: fixed;
}

body{
    background-color: $black;
    margin: 0;
    overflow-x: hidden;
}

.background{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: -90;
    background-color: #fff;
    background-size: 100%;
    opacity: .06;
    transition: 1s ease;
    background-repeat: no-repeat;
    background-position: center;
}


.finary{
    background-image: url('https://res.cloudinary.com/dd5hmji3j/image/upload/v1672439543/website/finary/thFinary02_t0k3q5.jpg');
    background-size: cover;
    background-position: center;

}

.powens{
    background-image: url('https://res.cloudinary.com/dd5hmji3j/image/upload/v1672162323/website/Powens/Powens08_1_zukbby.jpg');
    background-position: center;
    background-size: cover;

    
}

.kompozite{
    background-image: url('https://res.cloudinary.com/dd5hmji3j/image/upload/v1672789794/website/kompozite/hero_ffs1vx.jpg');
    background-position: center;
    background-size: cover;
}


.bling{
    background-size: cover;
    background-position: center;
    background-image: url('https://res.cloudinary.com/dd5hmji3j/image/upload/v1672421593/website/bling/Bling_5_k0s7dj.jpg');
}


.cubyn{
    background-size: cover;
    background-position: center;
    background-image: url('https://res.cloudinary.com/dd5hmji3j/image/upload/v1672425304/website/cubyn/header_oiywwp.jpg');
}



.hide-on-mobile{
    display: none;
}

.hide-on-desktop{
    display: initial;
}

.hide{
    display: none;
}

.bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: url('../assets/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .1s infinite;
    opacity: .7;
    visibility: visible;
  }


  .bodyContainer{
    height: 100vh;
    width: 100%;
    margin: 0 auto;
}

.projectContainer{
    margin: 0 24px 40px;
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.thumbnail{
    display: flex;
    width: 100%;
    gap:40px;
    flex-direction: column;

    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0,0);
    }
}


.italic{
    font-family: 'eiko_light';
}

// .thumbnail:hover{
//     cursor: url("img/cursorHover.svg") 4 12, auto;
// }


.imgContainer{
    display: flex;
    width: 100%;
    flex-grow: 1;
    height: 250px;
    overflow: hidden;
    opacity: .6;
}

.thumbnail p{
    font-family: 'neue_montreal_regular';
    color: $white;
    font-size: 16px;
}

.videoContainer{
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    position: absolute;
}

.fixedBkg{
    z-index: -  1;
    position: absolute;
    margin: 0 auto;
    top: 50%;
    transform: translate(0,-50%) scale(4);
    right: 0;
}

.intro{
    width: 100%;
    color: $white;
    height: 100vh;
    text-align: center;
    font-size: 12vw;
    font-family: 'neue_montreal_thin';
    line-height: auto;
    overflow: hidden;

    
    video{
        display: none;
        z-index: 1;
        position: absolute;
        margin: 0 auto;
        top: 50%;
        transform: translate(0,-50%) scale(4);
        right: 0;
        animation: opacify 4s ease-out;
        animation-fill-mode: both;
    }

    p{
        z-index: 8;
    }
}


.introContainer{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width:90%;
    z-index: 3;
}

h2{
    font-family: 'neue_montreal_thin';
    text-transform: uppercase;
    color: $white;
    font-size: 10vw;
}

.caption{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    opacity: 1;
}

.thumbnail p{
    font-family: 'neue_montreal_regular';
    color: $white;
    font-size: 16px;
    opacity: .5;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: $white;
    opacity: .2;
}

.ths{
    width: 100%;
}


.projectTitle{
    width: 100%;
    font-family: 'neue_montreal_light';
    text-transform: uppercase;
    color: $white;
    font-size: 12vw;
    margin-top: 150px;
}





.dividerBold{
    width: 100%;
    height: 1px;
    background-color: $white;
    margin-top: 304px;
}

.projectDesc{
    width: 100%;
    font-size: 1.5vw;
    font-family: 'neue_montreal_light';
    color: $white;
    line-height: 1.4em;
    padding: 72px 0;

}



.projectImageContainer{
    display: flex;
    gap: 16px;

    :nth-last-child(1){
        margin-top: 128px;
    }
}










/*RESPONSIVE DESKTOP*/


@media only screen and (min-width: 768px) {
    .italic{
        font-family: 'eiko_light';
    }
    .videoContainer{
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 1;
    }

    .caption{
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        opacity: .2;
        transition: .4s ease;
    }

    .hide-on-mobile{
        display: initial;
    }
    
    .hide-on-desktop{
        display: none;
    }

    .hide{
        display: none;
    }

.bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: url('../assets/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .1s infinite;
    opacity: .7;
    visibility: visible;
  }

.bodyContainer{
    height: 100vh;
    width: 100%;
    margin: 0 auto;
}

.color{
    opacity: 1;
}

.projectContainer{
    margin: 0 40px 40px;
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.thumbnail{
    display: flex;
    width: 100%;
    gap:40px;
    flex-direction: row;
    
}

.imgContainer{
    width: 0%;
    flex-grow: 1;
    overflow: hidden;
    opacity: 1;
    display: flex;
    transition: all .4s ease-out;
    gap: 4px;
    height: var(--dynamicHeight);
}

.ths{
    flex-direction: row;
    border-radius: 10em;
    overflow: hidden;
    transform: translateY(50%);
    transition: .3s ease-in;
    flex-basis: 0;
    opacity: 0;
}

.autoHeight1{
    opacity: 1;
    transform: translateY(0%);
    transition: .3s ease-out;
}

.autoHeight2{
    opacity: 1;
    transform: translateY(0%);
    transition: .3s ease-out;
    transition-delay: .05s;
}

.autoHeight3{
    opacity: 1;
    transform: translateY(0%);
    transition: .3s ease-out;
    transition-delay: .1s;
}

.thumbnail img{
    transform: translate(-50%,-50%);
    top: 50%;
    left:50%;
    position: relative;
    object-fit: cover;
}

.anim{
    height: var(--dynamicHeight) !important;
}


.fixedBkg{
    z-index: -  1;
    position: absolute;
    margin: 0 auto;
    top: 50%;
    transform: translate(0,-50%) scale(1);
    right: 0;
    display: none;
}


.intro{
    width: 100%;
    color: $white;
    height: 100vh;
    text-align: center;
    font-size: 8vw;
    font-family: 'neue_montreal_thin';
    line-height: auto;

    

    video{
        display: initial;
        z-index: 1;
        position: absolute;
        margin: 0 auto;
        top: 50%;
        transform: translate(0,-50%);
        right: 0;
        opacity: .4;
    }

    p{
        z-index: 8;
    }
}

.introContainer{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 100%;
    z-index: 3;
}


.divider{
    width: 100%;
    height: 1px;
    background-color: $white;
    opacity: .2;
}


.listContainer{
    position: fixed;
    color:$white;
    z-index: 8;
    font-family: 'neue_montreal_regular';

    :hover{
        color:#fff;
    }
}

h2{
    font-family: 'neue_montreal_thin';
    text-transform: uppercase;
    color: $white;
    font-size: 8vw;
}

.caption{
    height: min-content;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
}

.thumbnail p{
    font-family: 'neue_montreal_regular';
    color: $white;
    font-size: 16px;
    opacity: .5;
}

div[data-shape="leftRounded"]{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    border-top-left-radius: 10em;
     border-bottom-left-radius: 10em;
     border-top-right-radius: .2em;
     border-bottom-right-radius: .2em;
}

div[data-shape="rightRounded"]{
    border-top-right-radius: 10em;
     border-bottom-right-radius: 10em;
     border-top-left-radius: .2em;
     border-bottom-left-radius: .2em;
}

div[data-shape="fullRounded"]{
     border-radius:10em;
}

div[data-shape="fullSquare"]{
     border-top-left-radius: .2em;
     border-bottom-left-radius: .2em;
     border-top-right-radius: .2em;
     border-bottom-right-radius: .2em;
}

div[data-shape="topLeftRounded"]{
    border-top-left-radius: 10em;
     border-bottom-left-radius: .2em;
     border-top-right-radius: .2em;
     border-bottom-right-radius: .2em;
}

div [data-flex="large"]{
    flex-grow: 2;
    flex-shrink: 1;
}

div [data-flex="medium"]{
    flex-grow: 1.5;
    flex-shrink: 1;
}

div [data-flex="small"]{
    flex-grow: 1;
    flex-shrink: 2;
}

}



