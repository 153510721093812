

@font-face {
    font-family: 'neue_montreal_bold';
    src: url('./fonts/neue_montreal/neuemontreal-bold-webfont.woff2') format('woff2'),
         url('./fonts/neue_montreal/neuemontreal-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'neue_montreal_light';
    src: url('./fonts/neue_montreal/neuemontreal-light-webfont.woff2') format('woff2'),
         url('./fonts/neue_montreal/neuemontreal-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'neue_montreal_medium';
    src: url('./fonts/neue_montreal/neuemontreal-medium-webfont.woff2') format('woff2'),
         url('./fonts/neue_montreal/neuemontreal-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'neue_montreal_regular';
    src: url('./fonts/neue_montreal/neuemontreal-regular-webfont.woff2') format('woff2'),
         url('./fonts/neue_montreal/neuemontreal-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'neue_montreal_thin';
    src: url('./fonts/neue_montreal/ppneuemontreal-thin-webfont.woff2') format('woff2'),
         url('./fonts/neue_montreal/ppneuemontreal-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'eiko_light';
    src: url('./fonts/eiko/ppeiko-lightitalic-webfont.woff2') format('woff2'),
         url('./fonts/eiko/ppeiko-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


