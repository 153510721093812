.menu-container{
    color: $white;
    font-family: 'neue_montreal_light';
    margin: 40px auto 0;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    width: 100%;
    position:fixed;
    top:0;
}