@keyframes txt-animation {
    from{ transform: translate(0,100%) }
    to { transform: translate(0,0) }
}






.line-1, .line-2, .line-3, .line-4, .line-5, .line-6{
    display: block;
    overflow: hidden;
}

.line-1 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .4s;
    animation-fill-mode: both;
}

.line-2 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .45s;
    animation-fill-mode: both;
}

.line-3 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .5s;
    animation-fill-mode: both;
}

.line-4 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .6s;
    animation-fill-mode: both;
}

.line-5 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .7s;
    animation-fill-mode: both;
}

.line-6 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: .8s;
    animation-fill-mode: both;
}


@media only screen and (min-width: 768px) {

.line-1 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 2.4s;
    animation-fill-mode: both;
}

.line-2 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 2.6s;
    animation-fill-mode: both;
}

.line-3 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 2.8s;
    animation-fill-mode: both;
}

.line-4 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 3s;
    animation-fill-mode: both;
}

.line-5 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 3.2s;
    animation-fill-mode: both;
}

.line-6 p{
    transform: translate(0,0);
    animation: txt-animation 1s cubic-bezier(.4,0,.2,1);
    animation-delay: 3.4s;
    animation-fill-mode: both;
}

}