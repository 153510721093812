*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

* {
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

ul, li {
  list-style: none;
}

input, textarea, select, button {
  box-sizing: border-box;
  color: inherit;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  display: block;
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

input[type="submit"]:hover, button:hover {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

textarea {
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

img, video, svg, picture {
  display: block;
}

img, video {
  width: 100%;
  height: auto;
}

svg {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@font-face {
  font-family: neue_montreal_bold;
  src: url("neuemontreal-bold-webfont.6ba90cfa.woff2") format("woff2"), url("neuemontreal-bold-webfont.3c643403.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neue_montreal_light;
  src: url("neuemontreal-light-webfont.1817b162.woff2") format("woff2"), url("neuemontreal-light-webfont.29a733e8.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neue_montreal_medium;
  src: url("neuemontreal-medium-webfont.44045210.woff2") format("woff2"), url("neuemontreal-medium-webfont.6fdd6848.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neue_montreal_regular;
  src: url("neuemontreal-regular-webfont.a76b52ec.woff2") format("woff2"), url("neuemontreal-regular-webfont.1ba906d3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: neue_montreal_thin;
  src: url("ppneuemontreal-thin-webfont.bcc26d85.woff2") format("woff2"), url("ppneuemontreal-thin-webfont.7d6f4c19.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: eiko_light;
  src: url("ppeiko-lightitalic-webfont.dc41bef0.woff2") format("woff2"), url("ppeiko-lightitalic-webfont.c5bf0b64.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.text-green {
  color: #bada55;
}

.bg-green {
  background-color: #bada55;
}

.stroke-green {
  -webkit-text-stroke-color: #bada55;
}

.text-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.stroke-white {
  -webkit-text-stroke-color: #fff;
}

.text-black {
  color: #000;
}

.bg-black {
  background-color: #000;
}

.stroke-black {
  -webkit-text-stroke-color: #000;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  width: 11px;
  height: 100%;
  transform-origin: 100%;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

.menu-container {
  color: #fff;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin: 40px auto 0;
  font-family: neue_montreal_light;
  font-size: 16px;
  line-height: 32px;
  position: fixed;
  top: 0;
}

.row {
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  display: flex;
}

.col {
  width: 100%;
}

.col img, .col video {
  width: 100%;
  object-fit: cover;
  position: relative;
}

.projectPageContainer {
  width: 100%;
  flex-direction: column;
  gap: 16px;
  display: flex;
  position: absolute;
}

.next {
  margin-top: 80px;
  padding: 24px;
}

.txt {
  color: #fff;
  padding: 16px;
  font-family: neue_montreal_light;
  font-size: 7vw;
}

.projectHero {
  height: 100vh;
  z-index: -10;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  padding: 0 24px;
  font-family: neue_montreal_light;
  font-size: 12vw;
  top: 0;
  left: 0;
}

.projectHero h2 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) {
  .txt {
    color: #fff;
    padding: 40px;
    font-family: neue_montreal_light;
    font-size: 2.5vw;
  }

  .col {
    padding: 40px;
  }

  .projectHero {
    height: 100vh;
    z-index: -10;
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    padding: 0 40px;
    font-family: neue_montreal_light;
    font-size: 12vw;
    top: 0;
    left: 0;
  }

  .projectHero h2 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .projectPageContainer {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    display: flex;
    position: absolute;
  }

  .row {
    width: 100%;
    height: 100%;
    flex-direction: row;
    gap: 16px;
    padding: 0;
    display: flex;
  }

  .col img, .col video {
    width: 80%;
    object-fit: cover;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .next {
    margin-top: 80px;
    padding: 40px;
  }

  .last {
    margin-top: -450px;
  }

  div [data-random="1"] {
    transform: translate(-45%, -52%);
  }

  div [data-random="2"] {
    transform: translate(-52%, -48%);
  }

  div [data-random="3"] {
    transform: translate(-42%, -55%);
  }

  div [data-random="4"] {
    transform: translate(-47%, -51%);
  }

  div [data-random="5"] {
    transform: translate(-48%, -51%);
  }
}

.listContainer {
  color: #fff;
  z-index: 8;
  gap: 16px;
  font-family: neue_montreal_regular;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  position: fixed;
  top: 24px;
  right: 24px;
}

.skillsContainer {
  color: #fff;
  z-index: 8;
  gap: 16px;
  font-family: neue_montreal_regular;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  position: fixed;
  top: 24px;
  left: 24px;
}

.listContainer a {
  opacity: .5;
}

.listContainer a:hover, .skillsContainer a:hover {
  opacity: 1;
}

.skillsContainer a {
  opacity: .5;
}

@media only screen and (min-width: 768px) {
  .listContainer {
    color: #fff;
    font-size: 16dppx;
    z-index: 8;
    gap: 16px;
    font-family: neue_montreal_bold;
    line-height: 32px;
    display: flex;
    position: fixed;
    top: 40px;
    right: 40px;
  }

  .skillsContainer {
    color: #fff;
    z-index: 8;
    gap: 16px;
    font-family: neue_montreal_regular;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    position: fixed;
    top: 40px;
    left: 40px;
  }

  .listContainer a {
    opacity: .5;
  }

  .listContainer a:hover, .skillsContainer a:hover {
    opacity: 1;
  }

  .skillsContainer a {
    opacity: .5;
  }
}

@keyframes txt-animation {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0);
  }
}

.line-1, .line-2, .line-3, .line-4, .line-5, .line-6 {
  display: block;
  overflow: hidden;
}

.line-1 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .4s both txt-animation;
  transform: translate(0);
}

.line-2 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .45s both txt-animation;
  transform: translate(0);
}

.line-3 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .5s both txt-animation;
  transform: translate(0);
}

.line-4 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .6s both txt-animation;
  transform: translate(0);
}

.line-5 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .7s both txt-animation;
  transform: translate(0);
}

.line-6 p {
  animation: 1s cubic-bezier(.4, 0, .2, 1) .8s both txt-animation;
  transform: translate(0);
}

@media only screen and (min-width: 768px) {
  .line-1 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 2.4s both txt-animation;
    transform: translate(0);
  }

  .line-2 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 2.6s both txt-animation;
    transform: translate(0);
  }

  .line-3 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 2.8s both txt-animation;
    transform: translate(0);
  }

  .line-4 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 3s both txt-animation;
    transform: translate(0);
  }

  .line-5 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 3.2s both txt-animation;
    transform: translate(0);
  }

  .line-6 p {
    animation: 1s cubic-bezier(.4, 0, .2, 1) 3.4s both txt-animation;
    transform: translate(0);
  }
}

@keyframes bg-animation {
  0% {
    transform: translate(0);
  }

  10% {
    transform: translate(-5%, -5%);
  }

  20% {
    transform: translate(-10%, 5%);
  }

  30% {
    transform: translate(5%, -10%);
  }

  40% {
    transform: translate(-5%, 15%);
  }

  50% {
    transform: translate(-10%, 5%);
  }

  60% {
    transform: translate(15%);
  }

  70% {
    transform: translate(0, 10%);
  }

  80% {
    transform: translate(-15%);
  }

  90% {
    transform: translate(10%, 5%);
  }

  100% {
    transform: translate(5%);
  }
}

@keyframes opacify {
  from {
    opacity: 1;
  }

  to {
    opacity: .7;
  }
}

@keyframes rotate {
  from {
    transform: translate(-30%, -30%)rotate(0);
  }

  to {
    transform: translate(-30%, -30%)rotate(135deg);
  }
}

@keyframes rotateReverse {
  from {
    transform: translate(-30%, -30%)rotate(135deg);
  }

  to {
    transform: rotate(0)translate(-30%, -30%);
  }
}

* {
  cursor: none;
}

.ml13 .letter {
  line-height: 1em;
  display: inline-flex;
}

.cursor {
  width: 65px;
  height: 65px;
  backface-visibility: hidden;
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 900;
  background-color: #fff0;
  background-image: url("cursor.146f7be1.svg");
  background-repeat: no-repeat;
  position: fixed;
  overflow: visible;
  transform: translate(-30%, -30%);
}

.cursor-hover {
  mix-blend-mode: difference;
  background-image: url("cursorHover.e2d535ed.svg");
  position: fixed;
  overflow: visible;
  transform: translate(-30%, -30%);
}

.rotate {
  animation: .3s rotate;
  position: fixed;
  transform: translate(-30%, -30%)rotate(135deg);
}

.rotateReverse {
  animation: .3s forwards rotateReverse;
  position: fixed;
  transform: translate(-30%, -30%)rotate(135deg);
}

body {
  background-color: #000;
  margin: 0;
  overflow-x: hidden;
}

.background {
  width: 100%;
  height: 100%;
  z-index: -90;
  opacity: .06;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0;
  padding: 0;
  transition: all 1s;
  position: fixed;
}

.finary {
  background-image: url("https://res.cloudinary.com/dd5hmji3j/image/upload/v1672439543/website/finary/thFinary02_t0k3q5.jpg");
  background-position: center;
  background-size: cover;
}

.powens {
  background-image: url("https://res.cloudinary.com/dd5hmji3j/image/upload/v1672162323/website/Powens/Powens08_1_zukbby.jpg");
  background-position: center;
  background-size: cover;
}

.kompozite {
  background-image: url("https://res.cloudinary.com/dd5hmji3j/image/upload/v1672789794/website/kompozite/hero_ffs1vx.jpg");
  background-position: center;
  background-size: cover;
}

.bling {
  background-image: url("https://res.cloudinary.com/dd5hmji3j/image/upload/v1672421593/website/bling/Bling_5_k0s7dj.jpg");
  background-position: center;
  background-size: cover;
}

.cubyn {
  background-image: url("https://res.cloudinary.com/dd5hmji3j/image/upload/v1672425304/website/cubyn/header_oiywwp.jpg");
  background-position: center;
  background-size: cover;
}

.hide-on-mobile {
  display: none;
}

.hide-on-desktop {
  display: initial;
}

.hide {
  display: none;
}

.bg {
  width: 200%;
  height: 200vh;
  opacity: .7;
  visibility: visible;
  background: url("noise-transparent.d46abcb9.png");
  animation: .1s infinite bg-animation;
  position: fixed;
  inset: -50%;
}

.bodyContainer {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}

.projectContainer {
  flex-direction: column;
  gap: 40px;
  margin: 0 24px 40px;
  display: flex;
}

.thumbnail {
  width: 100%;
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.thumbnail img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0);
}

.italic {
  font-family: eiko_light;
}

.imgContainer {
  width: 100%;
  height: 250px;
  opacity: .6;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.thumbnail p {
  color: #fff;
  font-family: neue_montreal_regular;
  font-size: 16px;
}

.videoContainer {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  overflow: hidden;
}

.fixedBkg {
  z-index: -1;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%)scale(4);
}

.intro {
  width: 100%;
  color: #fff;
  height: 100vh;
  text-align: center;
  font-family: neue_montreal_thin;
  font-size: 12vw;
  line-height: auto;
  overflow: hidden;
}

.intro video {
  z-index: 1;
  margin: 0 auto;
  animation: 4s ease-out both opacify;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%)scale(4);
}

.intro p {
  z-index: 8;
}

.introContainer {
  width: 90%;
  z-index: 3;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  text-transform: uppercase;
  color: #fff;
  font-family: neue_montreal_thin;
  font-size: 10vw;
}

.caption {
  opacity: 1;
  flex-direction: column;
  flex-grow: 0;
  display: flex;
}

.thumbnail p {
  color: #fff;
  opacity: .5;
  font-family: neue_montreal_regular;
  font-size: 16px;
}

.divider {
  width: 100%;
  height: 1px;
  opacity: .2;
  background-color: #fff;
}

.ths {
  width: 100%;
}

.projectTitle {
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  margin-top: 150px;
  font-family: neue_montreal_light;
  font-size: 12vw;
}

.dividerBold {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-top: 304px;
}

.projectDesc {
  width: 100%;
  color: #fff;
  padding: 72px 0;
  font-family: neue_montreal_light;
  font-size: 1.5vw;
  line-height: 1.4em;
}

.projectImageContainer {
  gap: 16px;
  display: flex;
}

.projectImageContainer :nth-last-child(1) {
  margin-top: 128px;
}

@media only screen and (min-width: 768px) {
  .italic {
    font-family: eiko_light;
  }

  .videoContainer {
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .caption {
    opacity: .2;
    flex-direction: column;
    flex-grow: 0;
    transition: all .4s;
    display: flex;
  }

  .hide-on-mobile {
    display: initial;
  }

  .hide-on-desktop, .hide {
    display: none;
  }

  .bg {
    width: 200%;
    height: 200vh;
    opacity: .7;
    visibility: visible;
    background: url("noise-transparent.d46abcb9.png");
    animation: .1s infinite bg-animation;
    position: fixed;
    inset: -50%;
  }

  .bodyContainer {
    height: 100vh;
    width: 100%;
    margin: 0 auto;
  }

  .color {
    opacity: 1;
  }

  .projectContainer {
    flex-direction: column;
    gap: 40px;
    margin: 0 40px 40px;
    display: flex;
  }

  .thumbnail {
    width: 100%;
    flex-direction: row;
    gap: 40px;
    display: flex;
  }

  .imgContainer {
    width: 0%;
    opacity: 1;
    height: var(--dynamicHeight);
    flex-grow: 1;
    gap: 4px;
    transition: all .4s ease-out;
    display: flex;
    overflow: hidden;
  }

  .ths {
    opacity: 0;
    border-radius: 10em;
    flex-direction: row;
    flex-basis: 0;
    transition: all .3s ease-in;
    overflow: hidden;
    transform: translateY(50%);
  }

  .autoHeight1 {
    opacity: 1;
    transition: all .3s ease-out;
    transform: translateY(0%);
  }

  .autoHeight2 {
    opacity: 1;
    transition: all .3s ease-out 50ms;
    transform: translateY(0%);
  }

  .autoHeight3 {
    opacity: 1;
    transition: all .3s ease-out .1s;
    transform: translateY(0%);
  }

  .thumbnail img {
    object-fit: cover;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .anim {
    height: var(--dynamicHeight) !important;
  }

  .fixedBkg {
    z-index: -1;
    margin: 0 auto;
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%)scale(1);
  }

  .intro {
    width: 100%;
    color: #fff;
    height: 100vh;
    text-align: center;
    font-family: neue_montreal_thin;
    font-size: 8vw;
    line-height: auto;
  }

  .intro video {
    display: initial;
    z-index: 1;
    opacity: .4;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  .intro p {
    z-index: 8;
  }

  .introContainer {
    width: 100%;
    z-index: 3;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .divider {
    width: 100%;
    height: 1px;
    opacity: .2;
    background-color: #fff;
  }

  .listContainer {
    color: #fff;
    z-index: 8;
    font-family: neue_montreal_regular;
    position: fixed;
  }

  .listContainer :hover {
    color: #fff;
  }

  h2 {
    text-transform: uppercase;
    color: #fff;
    font-family: neue_montreal_thin;
    font-size: 8vw;
  }

  .caption {
    height: min-content;
    flex-direction: column;
    flex-grow: 0;
    display: flex;
  }

  .thumbnail p {
    color: #fff;
    opacity: .5;
    font-family: neue_montreal_regular;
    font-size: 16px;
  }

  div[data-shape="leftRounded"] {
    border-radius: 10em .2em .2em 10em;
    flex: 1 1 0;
  }

  div[data-shape="rightRounded"] {
    border-radius: .2em 10em 10em .2em;
  }

  div[data-shape="fullRounded"] {
    border-radius: 10em;
  }

  div[data-shape="fullSquare"] {
    border-radius: .2em;
  }

  div[data-shape="topLeftRounded"] {
    border-radius: 10em .2em .2em;
  }

  div [data-flex="large"] {
    flex-grow: 2;
    flex-shrink: 1;
  }

  div [data-flex="medium"] {
    flex-grow: 1.5;
    flex-shrink: 1;
  }

  div [data-flex="small"] {
    flex-grow: 1;
    flex-shrink: 2;
  }
}

/*# sourceMappingURL=index.7e1a8e8b.css.map */
