@each $color in $colors {
  .text-#{nth($color, 1)} {
    color: nth($color, 2);
  }

  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2);
  }

  .stroke-#{nth($color, 1)} {
    -webkit-text-stroke-color: nth($color, 2);
  }
}