.listContainer{
    display: flex;
    gap: 16px;
    color: $white;
    position: fixed;
    top: 24px;
    right: 24px;
    font-size: 16px;
    font-family: 'neue_montreal_regular';
    line-height: 32px;
    z-index: 8;
}

.skillsContainer{
    display: flex;
    color: $white;
    gap: 16px;
    position: fixed;
    top: 24px;
    left: 24px;
    font-size: 16px;
    font-family: 'neue_montreal_regular';
    line-height: 32px;
    z-index: 8;
}

.listContainer a{
    opacity: .5;
    
}

.listContainer a:hover{
    opacity: 1;
}

.skillsContainer a:hover{
    opacity: 1;
}

.skillsContainer a{
    opacity: .5;
}

  
@media only screen and (min-width: 768px) {
    .listContainer{
        display: flex;
        gap: 16px;
        color: $white;
        position: fixed;
        top: 40px;
        right: 40px;
        font-size: 16x;
        font-family: 'neue_montreal_bold';
        line-height: 32px;
        z-index: 8;
    }
    
    .skillsContainer{
        display: flex;
        color: $white;
        gap: 16px;
        position: fixed;
        top: 40px;
        left: 40px;
        font-size: 16px;
        font-family: 'neue_montreal_regular';
        line-height: 32px;
        z-index: 8;
    }
    
    .listContainer a{
        opacity: .5;
        
    }
    
    .listContainer a:hover{
        opacity: 1;
    }
    
    .skillsContainer a:hover{
        opacity: 1;
    }
    
    .skillsContainer a{
        opacity: .5;
    }
}